import React from "react";
import "./Rec.scss";
// import RecImg1 from "../../../assets/topSlide.png";
import RecImg1 from "../../../assets/rec.png";
import RecImg2 from "../../../assets/rec1.png";
import RecImg3 from "../../../assets/rec2.png";

const Rec = () => {
  return (
    <div className="recommendation">
      <div className="title">
        <h2>Аромат твоих воспоминаний</h2>
        <p>
          СИВИФ- это молодая развивающаяся компания. Мы очень дорожим своей
          репутацией, поэтому предоставляем нашим клиентам качественную,
          современную, разнообразную продукцию и достойный сервис обслуживания.
          Мы уверены, что парфюмерная продукция СИ ВИФ будет соответствовать
          вашим ожиданиям и радовать вас каждый день.
        </p>
      </div>
      <div className="container">
        <div className="row recCard">
          <div className="xl-6 lg-6 md-6 sm-12">
            <div className="recImg">
              <img src={RecImg1} alt="recommendation" />
            </div>
          </div>
          <div className="xl-6 lg-6 md-6 sm-12">
            <div className="recContext">
              <div className="recTitle">О Нас</div>
              <p>
                СИ'ВИФ- бренд селективной парфюмерии, создаваемой для людей,
                которые высоко ценят индивидуальность, выбирают качество,
                неповторимость ,стиль. Для создания наших ароматов мы используем
                только оригинальные парфюмерные масла, что является показателем
                качества нашей продукции и влияет на стойкость ароматов. 
                <br />В ассортименте продукции СИ'ВИФ есть не только парфюм, но
                и арома-диффузоры, спреи для дома, соевые свечи и ароматы для
                автомобиля.
              </p>
            </div>
          </div>
        </div>
        <div className="row recCard">
          <div className="xl-6 lg-6 md-6 sm-12">
            <div className="recImg">
              <img src={RecImg2} alt="recommendation" />
            </div>
          </div>
          <div className="xl-6 lg-6 md-6 sm-12">
            <div className="recContext">
              <div className="recTitle">Качество</div>
              <p>
                Мы получаем <br /> 100% качественное сырье в формате готовой
                композиции в наивысшей концентрации- парфюмерное масло. <br />
                 Вся продукция сертифицирована и проходит проверку на качество и
                безопасность.
              </p>
            </div>
          </div>
        </div>

        <div className="row recCard">
          <div className="xl-6 lg-6 md-6 sm-12">
            <div className="recImg">
              <img src={RecImg3} alt="recommendation" />
            </div>
          </div>
          <div className="xl-6 lg-6 md-6 sm-12">
            <div className="recContext">
              <div className="recTitle">Концентрация</div>
              <p>
                Все ароматы представлены в концентрации Extract de parfum.{" "}
                <br />
                 Extract de parfum содержит в себе 40% ароматических масел, что
                позволяет сохранить стойкость аромата и увеличить его шлейф.
                Ароматы доступны в объемах 15 мл, 30 мл, 50 мл и 100 мл <br /> с
                мелкодисперсным распылителем. На коже аромат держится более 12
                часов, многогранно раскрываясь на протяжении всего звучания.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
export default Rec