import React, { useState, useEffect } from "react";
import "../Support.scss";
import BreadCrumb from "../../../components/BreadCrumb";
import SupportSidebar from "../../../components/SupportSidebar";

import Meta from "../../../components/Meta/Meta";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";

const Payment = () => {
  const [payment, setPayment] = useState("");
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    ApiService.get("static/details?id=1586").then((resp) => {
      setPayment(resp.data.results.content);
      setPageMeta(resp.data.results.meta);
      setBreadcrumbs(resp.data.results.meta.breadcrumbs);
    });
  }, []);

  return (
    <div className="support contact">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />

        <h2 className="title">{_t("title.support")}</h2>
        <div className="row">
          <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
            <SupportSidebar />
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            <div className="supportMain">
              <div className="supportHeader">
                <h4>{payment.title}</h4>
              </div>
              <div className="supportBody">
                <div className="supportBodyCenter">
                  <div
                    className="supportContent payment"
                    dangerouslySetInnerHTML={{
                      __html: payment.content,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
