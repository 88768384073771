import { ReactComponent as Cross } from "../assets/Cross.svg";
import { _t } from "../helpers/helpers";
import InputMask from "react-input-mask";
import React from "react";
import SimpleReactValidator from "simple-react-validator";

const AddressEditModal = ({
  isOpen,
  handleClose,
  address,
  setAddress,
  editAddress
}) => {
  const validator = new SimpleReactValidator();

  const handleOnChange = (key, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      editAddress(address);
    } else {
      validator.showMessages();
    }
  }

  return isOpen ? (
    <div className="popup">
      <div className="popupContainer">
        <form action="" className="addressPopup" onSubmit={handleSubmit}>
          <button className="close" onClick={handleClose}>
            <Cross />
          </button>
          <div className="input-group">
            <div className="input-child">
              <label htmlFor="">{_t("title.adress")}</label>
              <input
                type="text"
                name="address_name"
                value={address.address_name}
                onChange={(e) => handleOnChange("address_name", e.target.value)}
              />
              {validator.message("address name", address.address_name, "required")}
            </div>
          </div>
          <div className="input-group">
            <div className="input-child">
              <label htmlFor="">{_t("contacts.full_address")}</label>
              <input
                type="text"
                value={address.full_address}
                onChange={(e) => handleOnChange("full_address", e.target.value)}
              />
              {validator.message("full address", address.full_address, "required")}
            </div>
          </div>
          <div className="input-group">
            <div className="input-child">
              <label htmlFor=""> {_t("title.city_region")}</label>
              <input type="text" value={address.city} onChange={
                (e) => handleOnChange("city", e.target.value)
              } />
              {validator.message("city", address.city, "required")}
            </div>
          </div>
          <div className="input-group">
            <div className="input-child">
              <label htmlFor=""> {_t("forms.numbers")}</label>
              <InputMask
                mask="+79999999999"
                maskChar=" "
                value={address.phone}
                onChange={(e) => handleOnChange("phone", e.target.value)}
              />
              {validator.message("phone", address.phone, "required")}
            </div>
          </div>
          <div className="input-group">
            <div className="input-child">
              <button className="blackButton">
                {_t("edit_address")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : null;
}

export default AddressEditModal