import React, { Component, useContext, useEffect, useState } from "react";
import "./Favorites.scss";
import AccountSidebar from "../../../components/AccountSidebar";
import BreadCrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import ProductCard from "../../../components/ProductCard";
import LogOut from "../../../components/Logout/LogOut";
import ApiService from "../../../api/axios";
import Meta from "../../../components/Meta/Meta";
import { _t } from "../../../helpers/helpers";
import { ReactComponent as Logo } from "../../../assets/heart.svg";
import { tokenContext } from "../../../components/Store";

const Favorites = () => {
  const [wishlists, setWishlists] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [token, setToken] = useContext(tokenContext);

  useEffect(() => {
    init();
  }, []);
  const init = () => {
    ApiService.get("account/wishlist", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((resp) => {
      console.log(resp.data.results.data);
      setWishlists(resp.data.results.data);
      // setPageMeta(resp.data.page_meta);
      // setBreadcrumbs(resp.data.page_meta.breadcrumbs);
    });
  };

  return (
    <div className="profile favoritesPage products">
      <div className="container">
        <Meta meta={pageMeta} />
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="account-header">
          <div className="title">
            <h5>{_t("pages.personal_info.title")}</h5>
            <p>{_t("account_content")}</p>
            <LogOut />
          </div>
        </div>
        <div className="row">
          <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
            <AccountSidebar />
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            {wishlists.length ? (
              <div className="account-fav">
                <h5 className="account-title">{_t("title_personal_info")}</h5>{" "}
                <div className="row">
                  {wishlists.map((wishlist) => {
                    return (
                      <div className="xl-4 lg-4 md-4 sm-6" key={wishlist.id}>
                        <ProductCard product={wishlist} />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="xl-12 lg-12 md-12 sm-12">
                  <div className="cartEmpty">
                    <div className="cartEptyContent">
                      <Link to="/">
                        <Logo />
                      </Link>
                      <p>{_t("favorites.empty")}</p>
                      <Link
                        to="/products"
                        className="blackButton cartEmptyButton"
                      >
                        {_t("buttons.back_to_shop")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Favorites;
