import React, { useContext, useEffect, useState } from "react";
import "./OrderHistory.scss";
import AccountSidebar from "../../../components/AccountSidebar";
import BreadCrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import { ReactComponent as Manat } from "../../../assets/rubl.svg";
import { ReactComponent as Logo } from "../../../assets/logo.svg";

import OrderHistoryCard from "../../../components/OrderHistoryCard";
import LogOut from "../../../components/Logout/LogOut";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";
import { tokenContext } from "../../../components/Store";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import { render } from "@testing-library/react";
import OrderImg from "../../../assets/product.png";
import { ReactComponent as ManatBlack } from "../../../assets/rubl.svg";
import { Rating } from "react-simple-star-rating";
import Meta from "../../../components/Meta/Meta";
// import Swal from "sweetalert2";

const OrderHistory = () => {
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [alreadyRated, setAlreadyRated] = useState(false);
  const [orderHistories, setOrderHistories] = useState({});
  const [product, setProduct] = useState({});
  const [volume, setVolume] = useState({});

  const [token, setToken] = useContext(tokenContext);
  // const [date, setDate] = useState();
  //   const [totalPrice, setTotalPrice] = useState(0);

  // Catch Rating value
  const handleRating = (rate, product_id) => {
    ApiService.post(
      "/account/rate",
      { star: rate / 20, product_id: product_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((res) => {
      setAlreadyRated(false);
      // Swal.fire(res.data.title, res.data.description, "success");
    });
  };

  useEffect(() => {
    ApiService.get("account/orders", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((resp) => {
      setOrderHistories(resp.data.data);
      setPageMeta(resp.data.meta);
      setBreadcrumbs(resp.data.meta.breadcrumbs);
      //   setTotalPrice(resp.data.total);
      // setDate(resp.data.data.date);
      console.log(resp.data.data);
    });
  }, []);
  return (
    <div className="profile orderHistoryPage">
      <Meta meta={pageMeta} />

      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />

        <div className="account-header">
          <div className="title">
            <h5>{_t("pages.personal_info.title")}</h5>
            <p>{_t("account_content")}</p>
            <LogOut />
          </div>
        </div>
        <div className="row">
          <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
            <AccountSidebar />
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            {orderHistories ? (
              <div className="account-order">
                <div className="accountCard accountCardParent">
                  <span>{_t("order.number")}</span>
                  <span>{_t("table.header.date")}</span>
                  <span>{_t("table.header.total")}</span>
                  <span>{_t("table.header.order.status")}</span>
                  <span>{_t("table.header.payment_status")}</span>
                </div>
                {Object.keys(orderHistories).map((key, orderHistory) => {
                  return (
                    <Accordion>
                      <AccordionSummary
                        // expandIcon={<Arrow />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="orderHistoryCardParent"
                      >
                        <div className="account-order-header accountCard">
                          <span>{key}</span>
                          <span>{orderHistories[key][0].date}</span>
                          <span>{orderHistories[key][0].total_price}</span>
                          <span>{orderHistories[key][0].status}</span>
                          <span>{orderHistories[key][0].payment_type}</span>
                        </div>

                        <Arrow />
                      </AccordionSummary>
                      {orderHistories[key][0].order_items.map((item) => {
                        return (
                          <AccordionDetails>
                            <div className="accountOrderCard">
                              <div className="orderHistoryCard">
                                <div className="imgContainer">
                                  <img
                                    src={
                                      item.product !== null
                                        ? item.product.title_image
                                        : null
                                    }
                                    alt="order img"
                                  />
                                </div>
                                <div className="orderHistoryDetail">
                                  <h6>
                                    {item.product !== null
                                      ? item.product.title
                                      : null}
                                  </h6>
                                  <div className="rating-line">
                                    <div className="rating-card">
                                      <Rating
                                        fillColor={"#A80E13"}
                                        readonly={
                                          item.product !== null
                                            ? item.product.isRated
                                            : null
                                        }
                                        allowHalfIcon={true}
                                        onClick={(rate) =>
                                          handleRating(
                                            rate,
                                            item.product !== null
                                              ? item.product.id
                                              : null
                                          )
                                        }
                                        iconsCount={5}
                                        size={20}
                                        ratingValue={
                                          item.product !== null
                                            ? item.product.rating
                                            : null * 20
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="orderHistoryDetailButton">
                                    <div className="left resp-none">
                                      <span>
                                        {item.count}
                                        {_t("title.count")}
                                      </span>
                                    </div>
                                    <div className="right">
                                      {item.volume !== null ? (
                                        <p>
                                          {item.volume.price ==
                                          item.volume.sale_price
                                            ? item.volume.price
                                            : item.volume.sale_price}
                                          <Manat />
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        );
                      })}
                      <div className="orderDelivery">
                        {/* <div className="orderDeliveryChild">
                          {_t("label.total_order_amount")}:
                          <span>
                            {orderHistories[key][0].total_price} рубль
                          </span>
                        </div>
                        <div className="orderDeliveryChild">
                          {_t("title.delivery")}:
                          <span>{orderHistories[key][0].delivery}</span>
                          0.00 рубль
                        </div> */}

                        <div className="orderDeliveryChild totalPriceOrder">
                          {_t("table.header.total_price")}:
                          <span>{orderHistories[key][0].total_price}</span>
                        </div>
                      </div>
                    </Accordion>
                  );
                })}
              </div>
            ) : (
              <div className="row">
                <div className="xl-12 lg-12 md-12 sm-12">
                  <div className="cartEmpty">
                    <div className="cartEptyContent">
                      <Link to="/">
                        <Logo />
                      </Link>
                      <p>{_t("favorites.empty")}</p>
                      <Link
                        to="/products"
                        className="blackButton cartEmptyButton"
                      >
                        {_t("buttons.back_to_shop")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
