import { useEffect } from "react";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
const MapWithAMarker = withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={14}
    defaultCenter={{
      lat: Number(props.latitude),
      lng: Number(props.longitude),
    }}
  >
    <Marker
      position={{ lat: Number(props.latitude), lng: Number(props.longitude) }}
    />
  </GoogleMap>
));

export default MapWithAMarker;
