import ApiService from "../api/axios";

export function emailValidation(email) {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !(regex.test(email) == false);
}

export function getLocale() {
  return "ru";
}

export function setTranslates() {
  ApiService.get("helpers/translates").then((resp) => {
    // if (!localStorage.translates) {
    // }
    localStorage.setItem("translates", JSON.stringify(resp.data.data));
  });
}

export function _t(keyword, params = [], locale = localStorage.locale) {
  let translates = localStorage.translates;

  if (translates) {
    if (
      JSON.parse(translates) &&
      JSON.parse(translates)[locale] &&
      JSON.parse(translates)[locale][keyword]
    ) {
      return JSON.parse(translates)[locale][keyword][0]["value"];
    }
    return keyword;
  }
}

export function normalizeLocalStorage(object, field1, field2) {
  let items = [];
  for (let key in object) {
    items.push({
      value: object[key][field1],
      label: object[key][field2].toUpperCase(),
    });
  }
  return items;
}

export function getApiUrl() {
  let baseURL = "";
  if (process.env.NODE_ENV === "development") {
    baseURL = "http://127.0.0.1:8000/";
  } else if (process.env.NODE_ENV === "production") {
    baseURL = "";
  }
  return baseURL;
}
