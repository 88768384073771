import React, { useEffect, useState } from "react";
import "./Collections.scss";
import { Link } from "react-router-dom";
import ApiService from "../../../api/axios";

const Collections = () => {
  const [collections, setCollections] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    ApiService.get("brands?special=1").then((resp) => {
      setCollections(resp.data.results.data);
      setPageMeta(resp.data.results.meta);
    });
  }, []);
  return (
    <div className="collections">
      <div className="title">
        <h4>{pageMeta.meta_title}</h4>
        <p>{pageMeta.description}</p>
      </div>
      <div className="category-box">
        {collections.map((collection) => (
          <Link
            to={`products?brand=${collection.alias}`}
            className="category-box-child"
          >
            <img src={collection.title_image} alt="category" />
            <h4>{collection.title}</h4>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Collections;
