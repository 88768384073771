import { Route, Switch } from "react-router-dom";
import "./index.scss";
// components
import Header from "./components/Header/Header";
import Footer from "./components/Footer";

// pages
import HomePage from "./pages/HomePage/HomePage";
import News from "./pages/News";
import Login from "./pages/Authentication/Login";
import Register from "./pages/Authentication/Register";
import Products from "./pages/Products";
import About from "./pages/About";
import NewsDetail from "./pages/NewsDetail";
import Error from "./pages/Error";
import ProductDetail from "./pages/ProductDetail";
import Cart from "./pages/Cart";
import Stores from "./pages/Stores";
import Brands from "./pages/Brands";
// account pages
import PersonalInfo from "./pages/Account/PersonalInfo";
import Favorites from "./pages/Account/Favorites";
import OrderHistory from "./pages/Account/OrderHistory";
import ChangePassword from "./pages/Account/ChangePassword";
import Address from "./pages/Account/Address";

// support pages
import Contact from "./pages/Support/Contact";
import OrderQuestion from "./pages/Support/OrderQuestion";
import Delivery from "./pages/Support/Delivery";
import Payment from "./pages/Support/Payment";
import DiscountCard from "./pages/Support/DiscountCard";
import Faq from "./pages/Support/Faq";
import GiftPackages from "./pages/GiftPackages";
import Terms from "./pages/Support/Terms";
import PrivacyPolicy from "./pages/Support/PrivacyPolicy";
import GiftCard from "./pages/GiftCard";
import DiscountC from "./pages/DiscountC";
import Create from "./pages/CreateYourPerfum";
import Collection from "./pages/Collection";
import SpecialPerfume from "./pages/SpecialPerfume";

import Find from "./pages/Find";
import FindSteps from "./pages/Find/FindSteps";
import React, { useState, useEffect, useContext } from "react";
import Meta from "./components/Meta/Meta";
import ApiService from "./api/axios";
import { setTranslates } from "./helpers/helpers";
// import PopupCart from "./components/PopupCart";
import { ReactComponent as ArrowTop } from "./assets/arrow.svg";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import { headermenusContext } from "./components/Store";
import CodeRegister from "./pages/Authentication/Code";
import ResetPassword from "./pages/Authentication/ResetPassword";
import StoreCategory from "./pages/StoreCategory";
const App = (props) => {
  const [allSettings, setAllSettings] = useState({
    numbers: [],
  });
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    if (!localStorage.guest_uid) {
      ApiService.get("cart/guest/uid/generate").then((resp) => {
        localStorage.setItem("guest_uid", resp.data.results.data);
      });
    }
    if (!localStorage.locale) {
      localStorage.setItem("locale", "ru");
    }

    setTranslates();
    ApiService.get("static/settings").then((res) => {
      setAllSettings(res.data.results.data);
    });
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const requireLogin = () => {
    let logged_in = false;
    if (localStorage.token) {
      logged_in = true;
    }
    if (!logged_in) {
      this.props.history.push("login");
    }
  };
  const supportedLanguages = ["en", "az", "ru"];

  return (
    <div className="App">
      <script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0"
        nonce="SWdKHEvs"
      ></script>
      <Meta
        meta={{
          meta_title: allSettings.meta_title,
          meta_description: allSettings.meta_description,
          meta_keywords: allSettings.meta_keywords,
        }}
      />
      <Header settings={allSettings} />
      <Switch>
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Route exact path="/code-register" component={CodeRegister} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/products/:alias" component={ProductDetail} />
        <Route exact path="/blog" component={News} />
        <Route path="/blog/:alias" component={NewsDetail} />
        <Route exact path="/about" component={About} />
        <Route exact path="/specials" component={Collection} />
        <Route exact path="/specials/:brand" component={Collection} />
        <Route exact path="/gift-cards" component={GiftCard} />
        <Route exact path="/gift-packages" component={GiftPackages} />
        <Route exact path="/create-your-perfume" component={Create} />
        <Route exact path="/special-perfumes" component={SpecialPerfume} />
        <Route exact path="/discount-cards" component={DiscountC} />
        <Route exact path="/stores" component={StoreCategory} />
        <Route exact path="/stores/:id" component={Stores} />
        <Route
          path="/support"
          render={({ match: { url } }) => (
            <>
              <Route
                exact
                path="/support/contact"
                component={() => <Contact settings={allSettings} />}
              />
              <Route
                exact
                path={`${url}/how-to-order`}
                component={OrderQuestion}
              />
              <Route exact path={`${url}/delivery`} component={Delivery} />
              <Route exact path={`${url}/payment`} component={Payment} />
              <Route
                exact
                path={`${url}/loyalty-program`}
                component={DiscountCard}
              />
              <Route exact path={`${url}/faq`} component={Faq} />
              <Route
                exact
                path={`${url}/terms-and-conditions`}
                component={Terms}
              />
              <Route
                exact
                path={`${url}/privacy-policy`}
                component={PrivacyPolicy}
              />
            </>
          )}
        />
        <Route exact path="/brands" component={Brands} />
        <Route exact path="/cart" component={Cart} />
        <Route
          onenter={() => requireLogin()}
          exact
          path="/account/personal-info"
          component={PersonalInfo}
        />
        <Route
          onenter={() => requireLogin()}
          exact
          path="/account/favorites"
          component={Favorites}
        />
        <Route
          onenter={() => requireLogin()}
          exact
          path="/account/change-password"
          component={ChangePassword}
        />
        <Route
          onenter={() => requireLogin()}
          exact
          path="/account/address"
          component={Address}
        />

        <Route
          onenter={() => requireLogin()}
          exact
          path="/account/order-history"
          component={OrderHistory}
        />

        {/*  <Route exact pat/h="/find-steps" component={FindSteps} /> */}

        <Route exact path="/" component={HomePage} />
        <Route exact path="*" component={Error} />
      </Switch>
      {/* {showTopBtn && (
        <div className="topToButton" onClick={goToTop}>
          <ArrowTop />
        </div>
      )} */}
      <Footer settings={allSettings} />
    </div>
  );
};

export default App;
