import React, { useEffect, useState } from "react";
import "../Authentication.scss";
import { _t } from "../../../helpers/helpers";
import ApiService from "../../../api/axios";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo.svg";

const CodeRegister = (props) => {
  const [code, setCode] = useState("");
  let validator = new SimpleReactValidator();
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  console.log(params);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      ApiService.post("/auth/register/verify", {
        code,
        phone: params.phone.replace(" ", "+"),
      }).then((resp) => {
        localStorage.setItem("token", resp.data.token);
        localStorage.setItem("wishlist", resp.data.user.wishlist);
        localStorage.setItem("full_name", resp.data.user.fullname);
        props.history.push("/");
        window.location.reload();
      });
    } else {
      validator.showMessages();
    }
  };
  return (
    <div className="authentication login">
      <div className="auth-content">
        <div className="left">
          <Link to="/">
            <Logo />
          </Link>
          <p>{_t("buttons_otp_code")}</p>
        </div>
        <div className="right">
          <div className="auth-content-child">
            <form onSubmit={handleSubmit} className="authContentBody">
              <div className="input-group input-group-validate">
                <input
                  type="number"
                  placeholder={_t("buttons_otp_code")}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <button className="blackButton">
                {/* <User /> */}
                {_t("buttons.send")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeRegister;
