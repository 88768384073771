import {Link} from "react-router-dom";
import {ReactComponent as Arrow} from '../../assets/arrow.svg'
import {Fragment} from "react";

const BreadCrumb = ({breadcrumbs}) => {
    return (
        <div className="breadCrumb">
            {
                breadcrumbs.map((value, index) => (
                (index !== (breadcrumbs.length-1)) ?
                    <Fragment key={index}>
                        <Link to={value.url}>
                            {value.title}
                        </Link>
                        <Arrow/>
                    </Fragment>
                    :
                    <Fragment key={index}>
                        <div>
                            {value.title}
                        </div>
                    </Fragment>
                ))
            }
        </div>
    )
}
export default BreadCrumb
