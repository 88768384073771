import React, { useContext, useState } from "react";
import "../Authentication.scss";
import { _t, emailValidation } from "../../../helpers/helpers";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { ReactComponent as User } from "../../../assets/user.svg";
import { Link } from "react-router-dom";
import ApiService from "../../../api/axios";
import SimpleReactValidator from "simple-react-validator";
import SocialLogin from "../SocialLogin";
import { tokenContext, fullNameContext } from "../../../components/Store";
import InputMask from "react-input-mask";

const Login = (props) => {
  const [password, setPassword] = useState("");
  const [token, setToken] = useContext(tokenContext);
  const [fullName, setFullName] = useContext(fullNameContext);
  const [email, setEmail] = useState("");
  let validator = new SimpleReactValidator();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      ApiService.post("auth/login", { email, password }).then((response) => {
        localStorage.setItem("token", response.data.results.token);
        setToken(response.data.results.token);
        localStorage.setItem("wishlist", response.data.results.user.wishlist);
        localStorage.setItem("full_name", response.data.results.user.fullname);
        setFullName(response.data.results.user.fullname);
        props.history.push("account/personal-info");
        console.log(response.data.results.token);
      });
    } else {
      validator.showMessages();
    }
  };
  return (
    <div className="authentication login">
      <div className="auth-content">
        <div className="left">
          <Link to="/">
            <Logo />
          </Link>
          <p>{_t("content_login")}</p>
        </div>
        <div className="right">
          <div className="auth-content-child">
            <div className="authContentChildHeader">
              <Link to="/login" className="Login active">
                {_t("pages.login.title")}
              </Link>
              <Link to="/register" className="Register">
                {_t("title.register")}
              </Link>
            </div>
            <form onSubmit={handleSubmit} className="authContentBody">
              <div className="input-group input-group-validate">
                <input
                  type="email"
                  required
                  placeholder={_t("forms.emails")}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {validator.message("email", email, "required")}
              </div>

              <div className="input-group input-group-validate">
                <input
                  type="password"
                  placeholder={_t("forms.password")}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {validator.message("password", password, "required|min:6")}
              </div>
              <div className="input-group">
                <button className="blackButton">
                  <User />
                  {_t("buttons_login")}
                </button>
              </div>
              <div className="authContentFooter">
                <div className="authContentFooterChild">
                  {/* <button>{_t("buttons_resend_code")}</button> */}
                  <Link to="/forget-password">
                    {_t("buttons_forgot_password")}
                  </Link>
                </div>
              </div>
            </form>
            {/* <SocialLogin /> */}
            {/* <div className="authContentFooter-guest">
              <Link to="/" className="contunie-guest">
                {_t("buttons_continue_guest")}
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
