import React, { useEffect, useState, useContext, useRef } from "react";
import "./HeaderMiddle.scss";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { ReactComponent as Search } from "../../../assets/search.svg";
import { ReactComponent as Tg } from "../../../assets/tg.svg";
import { ReactComponent as User } from "../../../assets/user.svg";
import { ReactComponent as Heart } from "../../../assets/heart.svg";
import { ReactComponent as Cart } from "../../../assets/cart.svg";
import { ReactComponent as Menu } from "../../../assets/Menu.svg";
import { ReactComponent as XButton } from "../../../assets/xButton.svg";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import { ReactComponent as Facebook } from "../../../assets/facebook.svg";
import { ReactComponent as Instagram } from "../../../assets/instagram.svg";
import { ReactComponent as Youtube } from "../../../assets/youtube.svg";
import { ReactComponent as Tiktok } from "../../../assets/tiktok.svg";
import { ReactComponent as Whatsapp } from "../../../assets/wp.svg";
import { ReactComponent as Vk } from "../../../assets/vk.svg";
import {
  cartItemsContext,
  quantityContext,
  localeContext,
  tokenContext,
  fullNameContext,
  headermenusContext,
} from "../../Store";
import { _t } from "../../../helpers/helpers";
import ApiService from "../../../api/axios";
import { ReactComponent as Phone } from "../../../assets/phone.svg";

const HeaderMiddle = (props) => {
  const [cartItems, setCartItems] = useContext(cartItemsContext);
  const [quantity, setQuantity] = useContext(quantityContext);
  const [headerMenuList, setHeaderMenuList] = useContext(headermenusContext);
  const [searchList, setSearchList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [locale, setLocale] = useContext(localeContext);
  const [fullName, setFullName] = useContext(fullNameContext);
  const [token, setToken] = useContext(tokenContext);
  // const [languages, setLanguages] = useState([]);
  const [gendersList, setGendersList] = useState([]);
  const [special_brand_menus, setSpecial_brand_menus] = useState([]);
  const [menuBrands, setMenuBrands] = useState([{ products: [] }]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [countItem, setCountItem] = useState("");

  useEffect(() => {
    // ApiService.get("helpers/languages").then((resp) => {
    //   setLanguages(resp.data.results.data);
    // });
    // init();

    ApiService.get("static/menu-products").then((resp) => {
      setGendersList(resp.data.results.data);
      setMenuBrands(resp.data.results.categories);
      console.log(gendersList);
    });
    ApiService.get("static/menu?place=header_menu").then((resp) => {
      setHeaderMenuList(resp.data.results.data);
      setSpecial_brand_menus(resp.data.results.special_brand_menus);
      setCampaigns(resp.data.results.campaigns);
    });
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const init = (items) => {
  //   if (token) {
  //     ApiService.get("cart/user/list", { cart_items: items }).then((res) => {
  //       setCountItem(res.data.results.quantity);
  //     });
  //   } else {
  //     ApiService.get("cart/guest/list", { cart_items: items }).then((res) => {
  //       setCountItem(res.data.results.quantity);
  //     });
  //   }
  // };
  const changeLocale = (e, language) => {
    ApiService.get(`helpers/locale/${language}`).then((resp) => {
      localStorage.setItem("locale", resp.data.data);
      window.location.reload();
    });
  };

  const searchFormPopup = (e) => {
    console.log(e.target.value);
    setKeyword(e.target.value);
    let query = e.target.value.trim();
    console.log(query);
    if (query.length != 0 || query != undefined || query != " ") {
      ApiService.get(`/products/search?keyword=${e.target.value}`).then(
        (res) => {
          setSearchList(res.data.results.data);
        }
      );
      setSearchOpen(true);
    }
  };

  /* Method that will fix header after a specific scrollable */
  const isSticky = () => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 400
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  const searchForm = () => {
    searchOpen(true);
  };
  // const searchKeyUp = () => {
  //   setSearch(true);
  // };

  const [menuBUttons, setMenuBUttons] = useState("");
  const menuButton = () => {
    setMenuBUttons(!menuBUttons);
    document.body.classList.toggle("body-overflow");
    setSearchMob(false);
  };
  const menuClose = () => {
    setMenuBUttons(false);
    document.body.classList.toggle("body-overflow");
  };
  // const [category, setCategory] = useState("");
  // const menuCategory = () => {
  //   setCategory(true);
  // };
  // const categoryClose = () => {
  //   setCategory(false);
  // };

  const [searchMob, setSearchMob] = useState("");
  const searchMobile = () => {
    setSearchMob(true);
    document.body.classList.toggle("body-overflow");
  };

  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setSearchOpen(false);
    }
  };
  const closeSearch = () => {
    setSearchOpen(!searchOpen);
  };
  const allMenuClose = () => {
    // setCategory(false);
    setSearchMob(!searchMob);
    document.body.classList.remove("body-overflow");
    setMenuBUttons(false);
  };
  // const handleClickInside = () => setSearchOpen(true);
  console.log(props.socials.wp_link);
  const location = useLocation();
  return (
    <div className="headerMiddleParent">
      <a href={props.socials.wp_link} target="_blank" className="desktopWpIcon">
        <Whatsapp />
      </a>
      <div className="headerMiddle header-section">
        <div className="headerTab">
          <div className="headerTabInner">
            <a
              href={props.socials.wp_link}
              target="_blank"
              className="headerTabIcon"
            >
              <Whatsapp />
              <span>Whatsapp</span>
            </a>
            <a href={`tel:${props.number.numbers}`} className="headerTabIcon">
              <Phone />
              <span>{_t("buttons.call")}</span>
            </a>
            {token ? (
              <Link to="/account/personal-info" className="headerTabIcon">
                <User />
                <span>{_t("pages.personal_info.title")}</span>
              </Link>
            ) : (
              <Link to="/login" className="headerTabIcon">
                <User />
                <span>{_t("buttons.login")}</span>
              </Link>
            )}
            <Link
              to="/cart"
              // onClick={menuClose}
              className="headerTabIcon cartCurrent "
            >
              <Cart />
              <span>{_t("buttons.basket")}</span>
              <span className="currentSpan">{quantity}</span>
            </Link>
          </div>
        </div>
        {/* mobile menu, category and search */}
        {menuBUttons ? (
          <div className="headerMiddleMenu">
            <div className="headerMiddleMenuTop">
              <button className="xButton" onClick={menuClose}>
                <XButton />
              </button>
              <Link to="/" className="logo" onClick={menuClose}>
                <Logo />
              </Link>
              <div className="menuLang" onClick={menuClose}>
                {/* {languages.map((language) => {
                  if (language.code != locale) {
                    return (
                      <button
                        key={language.id}
                        onClick={(e) => {
                          changeLocale(e, language.code);
                        }}
                      >
                        {language.code}
                      </button>
                    );
                  }
                })} */}
              </div>
            </div>
            <div className="headerMiddleMenuBody">
              <ul>
                {menuBrands.map((menuBrand) => {
                  return (
                    <li key={menuBrand.alias}>
                      <Link
                        className="title headerMiddleLInk"
                        to={`/products?categories=${menuBrand.alias}`}
                      >
                        {menuBrand.title}
                        <Arrow />
                      </Link>
                      <div className="headerMiddleMenuBody  headerMiddleMenuBodyInner">
                        <ul className="headerBottomMenuUl" onClick={menuClose}>
                          {menuBrand.products?.map((sub) => {
                            return (
                              <li key={sub.alias}>
                                <Link to={`/products/${sub.alias}`}>
                                  {sub.title}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </li>
                  );
                })}
                {/* {campaigns.map((campaign) => {
                  return (
                    <li>
                      <Link
                        to={`/products?sticker=${campaign.alias}`}
                        className="title headerMiddleLInk"
                        onClick={menuClose}
                      >
                        {campaign.title}
                      </Link>
                    </li>
                  );
                })} */}

                {headerMenuList.map((headerList) => {
                  return (
                    <li>
                      {headerList.sub_menus.length == 0 ? (
                        <Link
                          to={`/products/${headerList.path}`}
                          className="headerMiddleLInk"
                          // onClick={menuCategory}
                          onClick={menuClose}
                        >
                          {headerList.title}
                          {headerList.sub_menus.length == 0 ? null : <Arrow />}
                        </Link>
                      ) : (
                        <>
                          <Link
                            to={`/products/${headerList.path}`}
                            className="headerMiddleLInk"
                            // onClick={menuCategory}
                          >
                            {headerList.title}
                            {headerList.sub_menus.length == 0 ? null : (
                              <Arrow />
                            )}
                          </Link>
                          {headerList.sub_menus.map((sub_menu) => {
                            return (
                              <div className="headerMiddleMenuBody  headerMiddleMenuBodyInner">
                                <ul>
                                  <li onClick={menuClose}>
                                    <Link to={`/${sub_menu.path}`}>
                                      {sub_menu.title}
                                      {headerList.sub_menus.length ==
                                      0 ? null : (
                                        <Arrow />
                                      )}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
              <div className="middleMenuBody">
                {localStorage.token ? (
                  <Link to="/account/favorites" onClick={menuClose}>
                    <Heart />
                    {_t("buttons.favorite")}
                  </Link>
                ) : null}
                <Link to="/cart" onClick={menuClose} className="cartCurrent">
                  <Cart />
                  {_t("buttons.basket")}

                  <span className="currentSpan">{quantity}</span>
                </Link>
                <Link to="/login" onClick={menuClose}>
                  <User />
                  {_t("buttons.login")}
                </Link>
              </div>
              <div className="middleMenuBodyWp" onClick={menuClose}>
                <a
                  href={props.socials.wp_link}
                  target="_blank"
                  className="whatsapp-button"
                >
                  {console.log(props.socials.wp_link)}
                  <Whatsapp />
                </a>
                <a href={`tel:${props.number.numbers}`}>
                  {props.number.numbers}
                </a>
              </div>
              <div className="middleMenuBodySocial">
                <a
                  href={props.socials.facebook}
                  target="_blank"
                  onClick={menuClose}
                >
                  <Facebook />
                </a>
                <a
                  href={props.socials.instagram}
                  target="_blank"
                  onClick={menuClose}
                >
                  <Instagram />
                </a>
                <a
                  href={props.socials.youtube}
                  target="_blank"
                  onClick={menuClose}
                >
                  <Youtube />
                </a>
                <a
                  href={props.socials.tiktok}
                  target="_blank"
                  onClick={menuClose}
                >
                  <Tiktok />
                </a>

                {props.socials.facebook == "#" ? null : (
                  <a
                    target="_blank"
                    href={props.socials.facebook}
                    onClick={menuClose}
                  >
                    <Facebook />
                  </a>
                )}
                {props.socials.instagram == "#" ? null : (
                  <a
                    target="_blank"
                    href={props.socials.instagram}
                    onClick={menuClose}
                  >
                    <Instagram />
                  </a>
                )}
                {props.socials.youtube == "#" ? null : (
                  <a
                    target="_blank"
                    href={props.socials.youtube}
                    onClick={menuClose}
                  >
                    <Youtube />
                  </a>
                )}
                {props.socials.tiktok == "#" ? null : (
                  <a
                    target="_blank"
                    href={props.socials.tiktok}
                    onClick={menuClose}
                  >
                    <Tiktok />
                  </a>
                )}
                {props.socials.wp_link == "#" ? null : (
                  <a
                    target="_blank"
                    href={props.socials.wp_link}
                    onClick={menuClose}
                  >
                    <Whatsapp />
                  </a>
                )}
                {props.socials.vkontakte == "#" ? null : (
                  <a
                    target="_blank"
                    href={props.socials.vkontakte}
                    onClick={menuClose}
                  >
                    <Vk />
                  </a>
                )}
              </div>
            </div>
          </div>
        ) : null}
        {searchMob ? (
          <div className="searchMobilePopup">
            <div className="searchPopupInner">
              <div className="searchPopupInnerHeader">
                <form action="">
                  <input type="search" onKeyUp={(e) => searchFormPopup(e)} />

                  <Link to={`/products?keyword=${keyword}`}>
                    <Search />
                  </Link>
                </form>
                <button className="close" onClick={allMenuClose}>
                  {_t("buttons.back")}
                </button>
              </div>
              <div className="searchPopupInnerBody">
                {searchList.length > 0 ? (
                  <>
                    <div className="searchResultChild" onClick={allMenuClose}>
                      {searchList.map((product) => {
                        return (
                          <Link
                            to={`/products/${product.alias}`}
                            className="searchProduct"
                            // onClick={searchFormBlur}
                          >
                            <div className="img">
                              <img
                                src={product.title_image}
                                alt={product.title}
                              />
                            </div>
                            <p>{product.title}</p>
                          </Link>
                        );
                      })}
                    </div>
                    <Link
                      to={`/products?keyword=${keyword}`}
                      className="allParfumeSearch"
                      onClick={closeSearch}
                    >
                      {_t("buttons.allParfume")}
                    </Link>
                  </>
                ) : (
                  <div className="notFoundSearch">
                    {/* {_t("site_content.loading")} */}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}

        <div className="container">
          <div className="headerMiddleChild">
            <button className="menuButton" onClick={menuButton}>
              <Menu />
            </button>
            {location.pathname === "/" ? (
              <div className="logo deactive">
                <Logo />
              </div>
            ) : (
              <Link to="/" className="logo">
                <Logo />
              </Link>
            )}

            <button className="searchMobile" onClick={searchMobile}>
              <Search />
            </button>
            <div className="headerMiddleChild">
              <form
                className="headerSearch"
                ref={myRef}
                // onClick={handleClickInside}
              >
                <div className="searchForm">
                  <input
                    type="text"
                    id="searchForm"
                    placeholder={_t("inputs.search.placeholder")}
                    autoComplete="off"
                    onKeyUp={(e) => searchFormPopup(e)}
                  />
                  <Link to={`/products?keyword=${keyword}`}>
                    <Search />
                  </Link>
                </div>
                {searchOpen ? (
                  <div className="searchPopup">
                    <div className="searchPopupInner">
                      {keyword == "" ? ( // <div className="searchCategory">
                        //   <div className="title">{_t("most_extracted")}</div>
                        //   {gendersList.map((gender) => {
                        //     return (
                        //       <Link
                        //         to={`/products?gender=${gender.key}`}
                        //         onClick={closeSearch}
                        //       >
                        //         {gender.title}
                        //       </Link>
                        //     );
                        //   })}
                        // </div>
                        setSearchOpen(false)
                      ) : (
                        <>
                          <div className="title">{_t("search_result")}</div>
                          <div className="searchResult">
                            {searchList.length > 0 ? (
                              <>
                                <div className="searchResultChild">
                                  {searchList.map((product) => {
                                    return (
                                      <Link
                                        to={`/products/${product.alias}`}
                                        className="searchProduct"
                                        // onClick={searchFormBlur}
                                        onClick={closeSearch}
                                      >
                                        <div className="img">
                                          <img
                                            src={product.title_image}
                                            alt={product.title}
                                          />
                                        </div>
                                        <p>{product.title}</p>
                                      </Link>
                                    );
                                  })}
                                </div>
                                <Link
                                  to={`/products?keyword=${keyword}`}
                                  className="allParfumeSearch"
                                  onClick={closeSearch}
                                >
                                  {_t("buttons.allParfume")}
                                </Link>
                              </>
                            ) : (
                              <>
                                {searchList.length > 2 ? (
                                  <div className="notFoundSearch">
                                    {_t("error.not_found_message")}
                                  </div>
                                ) : (
                                  <div className="notFoundSearch">
                                    {_t("site_content.loading")}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </form>
              <div className="headerMiddleContentLink">
                <a
                  href={props.socials.wp_link}
                  className="whatsapp-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Whatsapp />
                </a>
                <a href={`tel:${props.number.numbers}`}>
                  {props.number.numbers}
                </a>
              </div>
            </div>
            <div className="headerMiddleAccount">
              {localStorage.token ? (
                <Link to="/account/personal-info">
                  <User />
                  <span>{localStorage.full_name}</span>
                </Link>
              ) : (
                <Link to="/login">
                  <User />
                  <span>{_t("buttons.login")}</span>
                </Link>
              )}
              {localStorage.token ? (
                <Link to="/account/favorites">
                  <Heart />
                  <span>{_t("buttons.favorite")}</span>
                </Link>
              ) : null}
              <Link to="/cart" className="cartCurrent">
                <Cart />
                <span>{_t("buttons.basket")}</span>
                <span className="currentSpan">{quantity}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMiddle;
