import React from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Link } from 'react-router-dom';
const Error = () => {
    return (
        <div className="errorPage">
            <div className="container">
                <div className="error-page-content">
                    <Link to="/">
                        <Logo />
                    </Link>
                    <p>
                        Təəssüfki axtardığınız səhifə tapılmadı
                    </p>
                </div>
                <div className="errorContentBottom">
                    <p>
                        Siz parfümünüzü aşağıdakı linklərdən tapa bilərsiniz::
                    </p>
                    <div className="errorContentBottomLink">
                        <Link to="/products">
                            Kişi ətirləri
                        </Link>
                        <Link to="/products">
                            Qadın ətirləri
                        </Link>
                        <Link to="/products">
                            Unisex ətirləri
                        </Link>
                        <Link to="/products">
                            Uşaq ətirləri
                        </Link>
                    </div>
                    <Link to="/" className="blackButton errorPageButton">
                        ana səhifəyə qayıt
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Error
