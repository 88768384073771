import React, { useEffect } from "react";
import HeaderTop from "../HeaderTop";
import HeaderMiddle from "../HeaderMiddle";
import HeaderBottom from "../HeaderBottom";
// import HeaderTab from '../HeaderTab'
const Header = ({ settings }) => {
  return (
    <div className="header">
      <HeaderTop
        socials={{
          facebook: settings.facebook,
          instagram: settings.instagram,
          youtube: settings.youtube,
          tiktok: settings.tiktok,
          telegram: settings.telegram,
          vkontakte: settings.vkontakte,
          wp_link: settings.wp_link,
        }}
      />
      {console.log(settings.wp_link)}
      <HeaderMiddle
        number={{ numbers: settings.numbers }}
        socials={{
          facebook: settings.facebook,
          instagram: settings.instagram,
          youtube: settings.youtube,
          tiktok: settings.tiktok,
          telegram: settings.telegram,
          vkontakte: settings.vkontakte,
          wp_link: settings.wp_link,
        }}
      />
      <HeaderBottom />
      {/* <HeaderTab /> */}
    </div>
  );
};

export default Header;
