import React from 'react';
import { ReactComponent as Left } from '../../assets/leftCircle.svg';
import FindImg from '../../assets/news.png';
import ProductCard from '../../components/ProductCard'
class MasterForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentStep: 1,
            email: '',
            username: '',
            password: '',
        }
    }

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    // handleSubmit = event => {
    //     event.preventDefault()
    //     const { email, username, password } = this.state
    //     alert(`Your registration detail: \n 
    //          Email: ${email} \n 
    //          Username: ${username} \n
    //          Password: ${password}`)
    // }

    _next = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep >= 2 >= 3 >= 4 ? 5 : currentStep + 1
        this.setState({
            currentStep: currentStep
        })
    }

    _prev = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep
        })
    }

    previousButton() {
        let currentStep = this.state.currentStep;
        if (currentStep !== 1) {
            return (
                <button
                    className="findPrev"
                    type="button" onClick={this._prev}>
                    <Left />
                </button>
            )
        }
        return null;
    }

    nextButton = () => {
        let currentStep = this.state.currentStep;
        if (currentStep < 5) {
            return (
                <button
                    className="findNext blackButton"
                    type="button" onClick={this._next}>
                    Növbətİ
                </button>
            )
        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
                <p className="step-p">{this.state.currentStep} /5</p>

                <form onSubmit={this.handleSubmit}>
                    <Step1
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                    // email={this.state.email}
                    />
                    <Step2
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                    // username={this.state.username}
                    />
                    <Step3
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                    // password={this.state.password}
                    />
                    <Step4
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                    // password={this.state.password}
                    />
                    <Step5
                        currentStep={this.state.currentStep}
                        handleChange={this.handleChange}
                    // password={this.state.password}
                    />
                    <div className="stepButtons">
                        {this.previousButton()}
                        {this.nextButton()}
                    </div>

                </form>
            </React.Fragment>
        );
    }
}

function Step1(props) {
    if (props.currentStep !== 1) {
        return null
    }
    return (
        <div className="findSection">
            <div className="findSectionChild">
                <h5>
                    Bu ƏTİrİ alıram..
                </h5>
                <div className="stepOneCheckbox">
                    <label htmlFor="" className="filterRadio">
                        <span>Mənim üçün</span>
                        <input type="radio" name="radio" checked id="" />
                        <span className="checkmark"></span>
                    </label>
                    <label htmlFor="" className="filterRadio">
                        <span>Hədiyyəlik</span>
                        <input type="radio" name="radio" id="" />
                        <span className="checkmark"></span>
                    </label>
                </div>
            </div>
            <h5>
                cİnsİ seçİN
            </h5>
            <div className="stepOneCheckbox">
                <label htmlFor="" className="filterRadio">
                    <span>Qadın</span>
                    <input type="radio" name="radio1" checked id="" />
                    <span className="checkmark"></span>
                </label>
                <label htmlFor="" className="filterRadio">
                    <span>Kişi</span>
                    <input type="radio" name="radio1" id="" />
                    <span className="checkmark"></span>
                </label>
            </div>
        </div>
    );
}

function Step2(props) {
    if (props.currentStep !== 2) {
        return null
    }
    return (
        <div className="findSection ">
            <div className="findSectionChild stepTwo  mb-0">
                <h5>
                    Ətrİn FəslİNİ seçİN..
                </h5>
                <div className="stepOneCheckbox">
                    <label htmlFor="" className="filterRadio filterRadioImg">
                        <img src={FindImg} alt="find" />
                        <div className="filterRadioFind">
                            <span>Qış/Payız ətiri</span>
                            <input type="radio" name="radio2" checked id="" />
                            <span className="checkmark"></span>
                        </div>
                    </label>
                    <label htmlFor="" className="filterRadio filterRadioImg">
                        <img src={FindImg} alt="find" />
                        <div className="filterRadioFind">
                            <span>Yay/ Yaz ətiri</span>
                            <input type="radio" name="radio2" id="" />
                            <span className="checkmark"></span>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    );
}

function Step3(props) {
    if (props.currentStep !== 3) {
        return null
    }
    return (
        <React.Fragment>
            <div className="findSection">
                <div className="findSectionChild stepTwo mb-0">
                    <h5>
                        Ətrİn vurulma vaxtını seçİN..
                    </h5>
                    <div className="stepOneCheckbox mb-0">
                        <label htmlFor="" className="filterRadio filterRadioImg">
                            <img src={FindImg} alt="find" />
                            <div className="filterRadioFind">
                                <span>Gecə Ətiri</span>
                                <input type="radio" name="radio3" checked id="" />
                                <span className="checkmark"></span>
                            </div>
                        </label>
                        <label htmlFor="" className="filterRadio filterRadioImg">
                            <img src={FindImg} alt="find" />
                            <div className="filterRadioFind">
                                <span>Gündüz Ətiri</span>
                                <input type="radio" name="radio3" id="" />
                                <span className="checkmark"></span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function Step4(props) {
    if (props.currentStep !== 4) {
        return null
    }
    return (
        <React.Fragment>
            <div className="findSection">
                <div className="findSectionChild stepFour mb-0">
                    <h5>
                        Ətrİn qrupunu seçİN..
                    </h5>
                    <div className="container">
                        <div className="row">
                            <div className="xl-3 lg-3 md-3 sm-6 findImgCard">
                                <label htmlFor="" className="filterRadio filterRadioImg">
                                    <img src={FindImg} alt="find" />
                                    <div className="filterRadioFind">
                                        <span>Gecə Ətiri</span>
                                        <input type="radio" name="radio4" checked id="" />
                                        <span className="checkmark"></span>
                                    </div>
                                </label>
                            </div>
                            <div className="xl-3 lg-3 md-3 sm-6 findImgCard">
                                <label htmlFor="" className="filterRadio filterRadioImg">
                                    <img src={FindImg} alt="find" />
                                    <div className="filterRadioFind">
                                        <span>Gündüz Ətiri</span>
                                        <input type="radio" name="radio4" id="" />
                                        <span className="checkmark"></span>
                                    </div>
                                </label>
                            </div>
                            <div className="xl-3 lg-3 md-3 sm-6 findImgCard">
                                <label htmlFor="" className="filterRadio filterRadioImg">
                                    <img src={FindImg} alt="find" />
                                    <div className="filterRadioFind">
                                        <span>Gündüz Ətiri</span>
                                        <input type="radio" name="radio4" id="" />
                                        <span className="checkmark"></span>
                                    </div>
                                </label>
                            </div>
                            <div className="xl-3 lg-3 md-3 sm-6 findImgCard">
                                <label htmlFor="" className="filterRadio filterRadioImg">
                                    <img src={FindImg} alt="find" />
                                    <div className="filterRadioFind">
                                        <span>Gündüz Ətiri</span>
                                        <input type="radio" name="radio4" id="" />
                                        <span className="checkmark"></span>
                                    </div>
                                </label>
                            </div>
                            <div className="xl-3 lg-3 md-3 sm-6 findImgCard">
                                <label htmlFor="" className="filterRadio filterRadioImg">
                                    <img src={FindImg} alt="find" />
                                    <div className="filterRadioFind">
                                        <span>Gündüz Ətiri</span>
                                        <input type="radio" name="radio4" id="" />
                                        <span className="checkmark"></span>
                                    </div>
                                </label>
                            </div>
                            <div className="xl-3 lg-3 md-3 sm-6 findImgCard">
                                <label htmlFor="" className="filterRadio filterRadioImg">
                                    <img src={FindImg} alt="find" />
                                    <div className="filterRadioFind">
                                        <span>Gündüz Ətiri</span>
                                        <input type="radio" name="radio4" id="" />
                                        <span className="checkmark"></span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
function Step5(props) {
    if (props.currentStep !== 5) {
        return null
    }
    return (
        <React.Fragment>
            <div className="findSection products">
                <div className="findSectionChild stepFive mb-0">
                    <h5>
                        Sİzİn seçİmİnİzə Uyğun Ətİrlər Tapıldı
                    </h5>
                    <div className="container">
                        <div className="row">
                            <div className="xl-3 lg-3 md-3 sm-6">
                                <ProductCard />
                            </div>
                            <div className="xl-3 lg-3 md-3 sm-6">
                                <ProductCard />
                            </div>
                            <div className="xl-3 lg-3 md-3 sm-6">
                                <ProductCard />
                            </div>
                            <div className="xl-3 lg-3 md-3 sm-6">
                                <ProductCard />
                            </div>
                            <div className="xl-3 lg-3 md-3 sm-6">
                                <ProductCard />
                            </div>
                            <div className="xl-3 lg-3 md-3 sm-6">
                                <ProductCard />
                            </div>
                            <div className="xl-3 lg-3 md-3 sm-6">
                                <ProductCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default MasterForm