import React, { Component } from "react";
import AccountSidebar from "../../../components/AccountSidebar";
import BreadCrumb from "../../../components/BreadCrumb";
import { Link } from "react-router-dom";
import { ReactComponent as Plus } from "../../../assets/add.svg";
import "./Address.scss";
import ApiService from "../../../api/axios";
import InputMask from "react-input-mask";
import { ReactComponent as Cross } from "../../../assets/Cross.svg";
import SimpleReactValidator from "simple-react-validator";
import LogOut from "../../../components/Logout/LogOut";
import Meta from "../../../components/Meta/Meta";
import AddressList from "../../../components/Address";
import { _t } from "../../../helpers/helpers";
class Address extends Component {
  constructor() {
    super();
    this.state = {
      pageMeta: {},
      breadcrumbs: [],
    };
  }
  init() {
    ApiService.get("account/address").then((resp) => {
      this.setState({ pageMeta: resp.data.results.meta });
      this.setState({ breadcrumbs: resp.data.results.meta.breadcrumbs });
    });
  }

  render() {
    return (
      <div className="profile address">
        <div className="container">
          <Meta meta={this.state.pageMeta} />
          <BreadCrumb breadcrumbs={this.state.breadcrumbs} />
          <div className="account-header">
            <div className="title">
              <h5>{_t("pages.personal_info.title")}</h5>
              <p>{_t("account_content")}</p>

              <LogOut />
            </div>
          </div>
          <div className="row">
            <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
              <AccountSidebar />
            </div>
            <div className="xl-9 lg-9 md-9 sm-12">
              <AddressList />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Address;
