import React, { useContext, useEffect, useState } from "react";
import "./About.scss";
import ApiService from "../../../api/axios";
import { localeContext } from "../../Store";
const About = (props) => {
  const [about, setAbout] = useState({});
  useEffect(() => {
    ApiService.get("static/details?id=7").then((resp) => {
      setAbout(resp.data.results.content);
    });
  }, []);
  return (
    <div className="aboutSection">
      <div className="container">
        <div className="aboutSectionChild">
          <div className="title">{about.title}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: about.content,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default About;
