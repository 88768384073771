import React, { useContext, useEffect, useState } from "react";
import { QuantityPicker } from "react-qty-picker";
import { Link } from "react-router-dom";
import { _t } from "../../helpers/helpers";
import {
  cartItemsContext,
  isActiveContext,
  quantityContext,
  tokenContext,
} from "../Store";
import { ReactComponent as Manat } from "../../assets/rubl.svg";
import { ReactComponent as Close } from "../../assets/x.svg";
// import Swal from "sweetalert2";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ApiService from "../../api/axios";
const AddToCartPopup = ({ product }) => {
  const [closeActive, setCloseActive] = useState(true);
  const [cartItems, setCartItems] = useContext(cartItemsContext);
  const [quantity, setQuantity] = useContext(quantityContext);
  const [count, setCount] = useState(1);
  const [volume_id, setVolume] = useState(0);
  const [token, setToken] = useContext(tokenContext);

  // useEffect(() => {

  // })

  const close = () => {
    setCloseActive(false);
  };

  const addToCart = (e, product_id) => {
    return localStorage.token
      ? ApiService.post("cart/user/create", {
          product_id,
          volume_id,
          count,
        }).then((resp) => {
          let item = { product_id, volume_id, count };

          let cart = cartItems;

          localStorage.setItem("cartItems", JSON.stringify(cart));
          setCartItems(cart);
          setQuantity(quantity + item.count);
          localStorage.setItem("quantity", quantity + item.count);
          // Swal.fire({
          //   position: "top-end",
          //   icon: "success",
          //   title: _t("message.basket_added"),
          //   showConfirmButton: false,
          //   timer: 1000,
          // });
          setCloseActive(false);
        })
      : ApiService.post("cart/guest/create", {
          product_id,
          volume_id,
          count,
          guest_id: localStorage.guest_uid,
        }).then((resp) => {
          let item = { product_id, volume_id, count };

          let cart = cartItems;

          localStorage.setItem("cartItems", JSON.stringify(cart));

          setCartItems(cart);
          setQuantity(quantity + item.count);
          localStorage.setItem("quantity", quantity + item.count);
          // Swal.fire({
          //   position: "top-end",
          //   icon: "success",
          //   title: _t("message.basket_added"),
          //   showConfirmButton: false,
          //   timer: 1000,
          // });
          setCloseActive(false);
        });
  };

  const changeVolume = (e) => {
    setVolume(e.target.value);
  };

  return closeActive ? (
    <div className="popupCart">
      <div className="popupCartInner">
        <div className="productCardPopup">
          <div className="productCardPopupChild">
            <div className="img-container">
              <LazyLoadImage
                src={product.title_image}
                alt="product img"
                className="aboutImg"
                effect="blur"
              />
              {console.log(product.title_image)}
            </div>
            <button className="closeButton" onClick={close}>
              <Close />
            </button>
            <div className="productPopupDetail">
              <h2>{product.title}</h2>
              <div className="productDetailSize">
                {product.volumes.map((volume, index) => (
                  <label htmlFor="">
                    <input
                      type="radio"
                      name="size"
                      onChange={(e) => changeVolume(e)}
                      value={volume.id}
                      className="volumeItem"
                      // defaultChecked={index === 0}
                    />
                    <div className="sizeCard">
                      <span>{volume.volume}</span>
                      {product.price_sale != product.price ? (
                        <div className="price">
                          {volume.price}
                          <Manat />
                          <span>
                            {volume.sale_price}
                            <Manat />
                          </span>
                        </div>
                      ) : (
                        <div className="price">
                          {volume.price}
                          <Manat />
                        </div>
                      )}
                    </div>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div className="productDetailQuantityPopup">
            <div className="quantity">
              <div className="productDetailQuantity">
                <QuantityPicker
                  value={count}
                  max={9999}
                  min={1}
                  onChange={(newValue) => {
                    setCount(newValue);
                  }}
                />
              </div>
            </div>
            {/* <Link to="/products" className="backToShop blackButton">
              {_t("cart.back_to_shop")}
            </Link> */}
            <button
              type="button"
              className="addToCard"
              onClick={(e) => addToCart(e, product.id)}
            >
              {_t("buttons.add_to_basket")}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default trackWindowScroll(AddToCartPopup);
