import React, { useEffect, useState } from "react";
import "../Support.scss";
import BreadCrumb from "../../../components/BreadCrumb";
import SupportSidebar from "../../../components/SupportSidebar";
import Meta from "../../../components/Meta/Meta";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";

const Terms = () => {
  const [terms, setTerms] = useState("");
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    ApiService.get("pageByUrl?url=terms-and-conditions").then((resp) => {
      console.log(resp.data.results.data);

      setTerms(resp.data.results.data);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
    });
  }, []);

  return (
    <div className="support contact">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <h2 className="title">{_t("title.support")}</h2>
        <div className="row">
          <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
            <SupportSidebar />
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            <div className="supportMain">
              <div className="supportHeader">
                <h4>{terms.title}</h4>
              </div>
              <div className="supportBody">
                <div className="supportBodyCenter">
                  <div className="termsContent">
                    {/* <h4>Şərtlər və qaydalar:</h4> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: terms.content,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
