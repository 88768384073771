import React, { useEffect, useState } from "react";
import "./Stores.scss";
import BreadCrumb from "../../components/BreadCrumb";
import { ReactComponent as Pin } from "../../assets/Pin.svg";
import { ReactComponent as Phone } from "../../assets/phone.svg";
import { ReactComponent as Time } from "../../assets/Time.svg";
import Meta from "../../components/Meta/Meta";
import "react-light-accordion/demo/css/index.css";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import ApiService from "../../api/axios";
import { Link, NavLink } from "react-router-dom";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import YouTube from "react-youtube";
import MapWithAMarker from "../../components/GoogleMaps";
import { _t } from "../../helpers/helpers";
const Stores = (props) => {
  const [storeList, setStoreList] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [activeStore, setActiveStore] = useState("");
  const [store, setStore] = useState({});
  const [id, setId] = useState(props.match.params.id);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    ApiService.get(`stores/list?city_id=${id}`).then((resp) => {
      setStoreList(resp.data.results.data);
      setPageMeta(resp.data.results.meta);
      setBreadcrumbs(resp.data.results.meta.breadcrumbs);
    });
    let activeStore = props.match.params.id;
    console.log(activeStore);
    if (activeStore !== undefined) {
      setActiveStore(activeStore);
      ApiService.get(`stores/show?url=${activeStore}`).then((resp) => {
        setStore(resp.data.results.content);
        setPageMeta(resp.data.results.meta);
        setBreadcrumbs(resp.data.results.meta.breadcrumbs);
      });
    } else {
    }
  }, [props.match.params.id]);

  const opts = {
    height: "413",
    width: "100%",
  };
  const activeButton = () => {
    setIsActive(true);
  };
  return (
    <div className="stores">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <h2 className="title">{_t("sidebar.stores")}</h2>
        <div className="row">
          <div className="xl-4 lg-4 md-4 sm-12">
            <div className="storeLeft">
              <div className="storeLeftCard" onClick={() => activeButton()}>
                {storeList.map((item) => (
                  <NavLink
                    activeClassName="active"
                    to={`/stores/${item.alias}`}
                  >
                    <span className="storeTitle">{item.title}</span>
                    <div className="storeLeftCardBody">
                      <p>
                        <Pin />
                        <span>{item.addresses}</span>
                      </p>
                      <p>
                        <Phone />
                        <div>
                          {item.numbers.map((number) => (
                            <span>{number}</span>
                          ))}
                        </div>
                      </p>
                      <p>
                        <Time />
                        {item.work_hours.map((work_hour) => {
                          return <span>{work_hour.hours}</span>;
                        })}
                      </p>
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <div className="xl-8 lg-8 md-8 sm-12">
            {isActive ? (
              <div className="storesMain" key={`${store.alias}`}>
                {/* <div className="storesMainVideo">
                <YouTube videoId={store.video_link} opts={opts} />
              </div> */}

                <div className="storesTop">
                  <div>
                    <label htmlFor="">{_t("forms.address")}</label>
                    <span>{store.title}</span>
                  </div>

                  <div>
                    <label htmlFor="">{_t("contacts.full_address")}</label>
                    <span>{store.addresses}</span>
                  </div>

                  <div>
                    <label htmlFor="">{_t("forms.numbers")}</label>
                    <span>{store.numbers}</span>
                  </div>
                </div>

                <MapWithAMarker
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  longitude={store.longitude}
                  latitude={store.latitude}
                />
              </div>
            ) : (
              <>
                {storeList.map((item) => (
                  <div className="storesMain storesMainFirst">
                    <div className="storesTop">
                      <div>
                        <label htmlFor="">{_t("forms.address")}</label>
                        <span>{item.title}</span>
                      </div>

                      <div>
                        <label htmlFor="">{_t("contacts.full_address")}</label>
                        <span>{item.addresses}</span>
                      </div>

                      <div>
                        <label htmlFor="">{_t("forms.numbers")}</label>
                        <span>{item.numbers}</span>
                      </div>
                    </div>

                    <MapWithAMarker
                      containerElement={<div style={{ height: `400px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      longitude={item.longitude}
                      latitude={item.latitude}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stores;
