import React, { useState, useEffect } from "react";
import "./Discount.scss";
import BreadCrumb from "../../components/BreadCrumb";
import GiftImg from "../../assets/blue.jpg";
import { Link } from "react-router-dom";
import { ReactComponent as Bag } from "../../assets/Bag.svg";
import Meta from "../../components/Meta/Meta";
import ApiService from "../../api/axios";

const Discount = () => {
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [giftCards, setGiftCard] = useState({ media: [] });
  useEffect(() => {
    ApiService.get("static/details?id=1591").then((resp) => {
      setGiftCard(resp.data.results.content);
      setBreadcrumbs(resp.data.results.meta.breadcrumbs);
      setPageMeta(resp.data.results.meta);
    });
  }, []);
  return (
    <div className="giftCard">
      <Meta meta={pageMeta} />
      <div className="container breadCrumbsContainer">
        <BreadCrumb breadcrumbs={breadcrumbs} />
      </div>

      <div className="giftBodyText">
        <div className="container">
          <div className="giftbodyTextchild">
            <div className="title">
              <h5>{giftCards.title}</h5>
              <p>{giftCards.description}</p>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: giftCards.content,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="collectionHeader">
          {/* <h4 className="title">{giftCards.title}</h4 */}
          {/* <p>{giftCard.description}</p> */}
        </div>

        <div className="giftBody">
          {giftCards.media.map((giftCard) => {
            return (
              <div className="giftCardChild">
                <img src={giftCard.slider} alt="gift img" />
                <div className="giftCardChildRight">
                  <h5 className="title">{giftCard.title}</h5>
                  <h6 className="subtitle">{giftCard.description}</h6>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: giftCard.content,
                    }}
                  />
                  {/* {giftCard.content} */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Discount;
