import React, { useContext } from "react";
// import Swal from "sweetalert2";
import ApiService from "../../api/axios";
import { Link, useHistory } from "react-router-dom";
import { fullNameContext, tokenContext } from "../Store";
import { _t } from "../../helpers/helpers";

const LogOut = () => {
  let history = useHistory();

  const [token, setToken] = useContext(tokenContext);
  const [fullName, setFullName] = useContext(fullNameContext);

  const logOut = () => {
    if (localStorage.token) {
      ApiService.post("account/logout", {
        headers: { Authorization: `Bearer ${token}` },
      }).then((resp) => {
        setToken(null);
        setFullName(null);
        localStorage.removeItem("token");
        localStorage.removeItem("full_name");
        history.push("/login");
        // Swal.fire(resp.data.title, resp.data.message, "success");
      });
    }
    if (token) {
      ApiService.post("account/logout", {
        headers: { Authorization: `Bearer ${token}` },
      }).then((resp) => {
        // Swal.fire({
        //   title: _t("warning.delete_question"),
        //   icon: "warning",
        //   showCancelButton: true,
        //   confirmButtonColor: "#3085d6",
        //   cancelButtonColor: "#d33",
        //   confirmButtonText: "OK",
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     setToken(null);
        //     setFullName(null);
        //     localStorage.removeItem("token");
        //     localStorage.removeItem("full_name");
        //     history.push("/login");
        //     Swal.fire(resp.data.message, "success");
        //   }
        // });
        setToken(null);
        setFullName(null);
        localStorage.removeItem("token");
        localStorage.removeItem("full_name");
        history.push("/login");
      });
    }
  };
  return (
    <div type="button" onClick={logOut} className="logOutButton resp-none">
      {_t("buttons.logout")}
    </div>
  );
};

export default LogOut;
