import React, { useEffect, useState } from "react";
import "./ContentCategory.scss";
import { Link } from "react-router-dom";
import Category from "../../../assets/catergory.png";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";

const ContentCategory = () => {
  const [about, setAbout] = useState({});
  useEffect(() => {
    ApiService.get("static/details?id=7").then((resp) => {
      setAbout(resp.data.results.content);
    });
  }, []);
  return (
    <div className="contentCategory">
      <div className="container-second">
        <div className="row">
          <div className="xl-6 lg-6 md-6 sm-12 ContentCategoryImg">
            <img src={about.title_image} alt="" />
          </div>
          <div className="xl-6 lg-6 md-6 sm-12">
            <div className="left">
              <h1>{about.title}</h1>
              <p>{about.description}</p>
              <div className="more-category">
                <Link to="/about">{_t("buttons.more_detail")}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentCategory;
