import React, { useContext, useEffect, useState } from "react";
import "./PersonalInfo.scss";
import BreadCrumb from "../../../components/BreadCrumb";
import AccountSidebar from "../../../components/AccountSidebar";
import InputMask from "react-input-mask";
import ApiService from "../../../api/axios";
import LogOut from "../../../components/Logout/LogOut";
import Meta from "../../../components/Meta/Meta";
import { tokenContext } from "../../../components/Store";
import { _t } from "../../../helpers/helpers";
// import Swal from "sweetalert2";

const PersonalInfo = () => {
  const [account, setAccount] = useState({});
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [token, setToken] = useContext(tokenContext);
  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  useEffect(() => {
    ApiService.get("account").then((resp) => {
      //   setPageMeta(resp.data.page_meta);
      //   setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      setFullName(resp.data.results.fullname);
      setEmail(resp.data.results.email);
      setPhone(resp.data.results.phone);
    });
  }, []);
  const handleForm = (e) => {
    e.preventDefault();
    ApiService.put("account", { full_name, email, phone }).then((resp) => {
      setFullName(resp.data.results.fullname);
      setEmail(resp.data.results.email);
      setPhone(resp.data.results.phone);
      console.log(resp.data.results.email);
      // Swal.fire(_t("success.title"), _t("success.message"), "success");
    });
  };
  return (
    <div className="profile personalInfo">
      <div className="container">
        <Meta meta={pageMeta} />
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="account-header">
          <div className="title">
            <h5>{pageMeta.meta_title}</h5>
            <p>{_t("account_content")}</p>
            <LogOut />
          </div>
        </div>
        <div className="row">
          <div className="xl-3 lg-3 md-3 sm-12 positionRelative">
            <AccountSidebar />
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            <div className="account-personal">
              <h5 className="account-title">{_t("title_personal_info")}</h5>
              <form action="" className="accountForm" onSubmit={handleForm}>
                <div className="input-group">
                  <div className="input-child">
                    <label htmlFor="">{_t("forms_name_surname")}</label>
                    <input
                      type="text"
                      name="fullname"
                      value={full_name}
                      placeholder={_t("forms_name_surname")}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>
                  <div className="input-child ">
                    <label htmlFor="">{_t("forms.numbers")}</label>
                    <InputMask
                      mask="+79999999999"
                      maskChar=" "
                      value={phone}
                      placeholder={_t("forms.numbers")}
                      onChange={(e) => setPhone(e.target.value)}
                      // disabled
                    />
                  </div>
                </div>
                <div className="input-group">
                  <div className="input-child">
                    <label htmlFor="">{_t("forms.emails")}</label>
                    <input
                      type="email"
                      value={email}
                      placeholder={_t("forms.emails")}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="input-child"></div>
                </div>
                <div className="input-group">
                  <div className="input-child">
                    <button className="blackButton">
                      {_t("buttons.send")}
                    </button>
                  </div>
                  <div className="input-child"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
