import axios from "axios";
// import Swal from "sweetalert2";
import { notification } from "antd";

let headers;
let baseURL;
if (process.env.NODE_ENV === "development") {
  baseURL = "https://api.sivif.ru/api";
} else if (process.env.NODE_ENV === "production") {
  baseURL = "https://api.sivif.ru/api";
}

try {
  headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Language: localStorage.getItem("locale"),
  };
} catch (e) {
  console.log(e);
}

const ApiService = axios.create({
  baseURL,
  timeout: 0,
  headers: headers,
});

ApiService.interceptors.request.use(
  function (config) {
    if (localStorage.token) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "token"
      )}`;
    }
    config.headers["Language"] = localStorage.getItem("locale");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

ApiService.interceptors.response.use(
  (response) => {
    // If there's an error in the response data, show a notification
    if (response.data.error) {
      notification.error({
        message: "Error",
        description: response.data.message, // Error message from response
        duration: 3, // Notification will disappear after 3 seconds
      });
      return Promise.reject(response.data.message); // Reject response to handle it elsewhere if needed
    } else {
      return response; // Proceed normally if no error
    }
  },
  (error) => {
    // Handle network or server errors and show a notification
    notification.error({
      message: "Error",
      description:
        error.response?.data?.message || "An unexpected error occurred", // Handle no error message case
      duration: 3, // Notification will disappear after 3 seconds
    });
    return Promise.reject(error); // Reject the error to handle elsewhere if needed
  }
);

export default ApiService;
