import React, { useContext, useState } from "react";
import "./ProductCard.scss";
import { ReactComponent as Heart } from "../../assets/heartMini.svg";
import { ReactComponent as ManatBlack } from "../../assets/rubl.svg";
import { ReactComponent as ManatPink } from "../../assets/rubl.svg";
import ApiService from "../../api/axios";
import { ReactComponent as Bag } from "../../assets/Bag.svg";

import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { _t } from "../../helpers/helpers";
import { addTosWishlist } from "../../helpers/functions";
import "../PopupCart/PopupCart.scss";
import { isActiveContext, tokenContext } from "../Store";
import AddToCartPopup from "./PopupAddToBasket";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const ProductCard = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [token, setToken] = useContext(tokenContext);
  const handleClick = () => {
    setIsActive(!isActive);
  };
  const removeButton = (e, id) => {
    ApiService.post("account/wishlist", { product_id: id }).then((resp) => {
      localStorage.setItem("wishlist", resp.data.results.wishlist);
      window.location.reload();
    });
  };

  return (
    <div>
      {isActive ? (
        <AddToCartPopup
          key={`product_item_${props.product.id}`}
          product={props.product}
        />
      ) : null}
      <div className="productCard">
        <div className="productCardHeader">
          <Link to={`/products/${props.product.alias}`}>
            <LazyLoadImage
              src={props.product.title_image}
              alt="product img"
              className="aboutImg"
              effect="blur"
            />
          </Link>
          {props.product.sticker ? (
            <div
              className="productCardBadge newProduct"
              style={{ backgroundColor: props.product.sticker.color }}
            >
              {props.product.sticker.title}
            </div>
          ) : null}
          {props.product.out_of_stock == 1 ? (
            <div className="productCardBadge stock">{_t("out of stock")}</div>
          ) : null}
          {token ? (
            <div>
              <label className="heart">
                <input
                  onClick={(e) => addTosWishlist(e, props.product.id)}
                  className="like-button"
                  checked={
                    localStorage.wishlist &&
                    localStorage.wishlist.includes(props.product.id)
                      ? "checked"
                      : null
                  }
                  type="checkbox"
                />
                <Heart />
              </label>
              <label className="likeTrash">
                <input
                  onClick={(e) => removeButton(e, props.product.id)}
                  className="trashButton"
                  checked={
                    localStorage.wishlist &&
                    localStorage.wishlist.includes(props.product.id)
                      ? "checked"
                      : null
                  }
                  type="checkbox"
                />
                {_t("buttons.delete")}
              </label>
            </div>
          ) : null}

          {props.product.sale_percent &&
          Number(props.product.sale_percent) > 0 ? (
            <div className="interest">{`${props.product.sale_percent} %`}</div>
          ) : null}

          {props.product.out_of_stock == 0 ? (
            <button className="productCardAddToCart" onClick={handleClick}>
              <Bag />
              {_t("buttons.add_to_basket")}
            </button>
          ) : null}
        </div>
        <div className="productCardBody">
          <div className="mobileproductCardBOdy">
            <div className="productCardBodyHeader">
              {props.product.min_price === props.product.max_price ? (
                <div className="price blackPrice">
                  <span>
                    {props.product.min_price}
                    <ManatBlack />
                  </span>
                </div>
              ) : (
                <>
                  {props.product.min_price_sale != props.product.min_price &&
                  props.product.max_sale_price != props.product.max_price &&
                  props.product.min_price_sale === 0 ? (
                    <div className="price">
                      <span>
                        {props.product.min_price_sale}
                        <ManatPink />
                      </span>
                      {props.product.max_sale_price === 0 ? null : (
                        <>
                          <span>-</span>
                          <span>
                            {props.product.max_sale_price}
                            <ManatPink />
                          </span>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="price blackPrice">
                      <span>
                        {props.product.min_price}
                        <ManatBlack />
                      </span>
                      {props.product.max_price === 0 ? null : (
                        <>
                          <span>-</span>
                          <span>
                            {props.product.max_price}
                            <ManatBlack />
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
              {props.product.min_price_sale === props.product.min_price &&
              props.product.max_sale_price === props.product.max_price &&
              props.product.min_price_sale === 0 &&
              props.product.max_sale_price === 0 ? (
                <div className="price priceDiscount">
                  <span className="discount">
                    {props.product.min_price}

                    <ManatBlack />
                  </span>
                  {props.product.max_price === 0 ? null : (
                    <>
                      <span>-</span>
                      <span className="discount">
                        {props.product.max_price}
                        <ManatBlack />
                      </span>
                    </>
                  )}
                </div>
              ) : null}
            </div>

            <div className="productCardRating desk-none">
              <Rating
                key={props.product.id}
                fillColor={"#A80E13"}
                size={10}
                ratingValue={props.product.rating} /* Available Props */
              />
            </div>
          </div>
          <Link t to={`/products/${props.product.alias}`} className="title">
            {props.product.title}
          </Link>
          <div className="productCardRating resp-none">
            <Rating
              fillColor={"#A80E13"}
              readonly={true}
              allowHalfIcon={true}
              iconsCount={5}
              size={20}
              ratingValue={props.product.rating * 20} /* Available Props */
            />
          </div>

          <button className="mobileAddToCart" onClick={handleClick}>
            <Bag />
            {_t("buttons.add_to_basket")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default trackWindowScroll(ProductCard);
