import React, { useEffect, useState } from "react";
import ApiService from "../api/axios";
import Quantity from "./Quantity";
export const cartItemsContext = React.createContext("cartItems");
export const quantityContext = React.createContext("quantity");
export const localeContext = React.createContext("localeContext");
export const tokenContext = React.createContext("tokenContext");
export const fullNameContext = React.createContext("fullNameContext");
export const headermenusContext = React.createContext("headermenusContext");

const Store = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    localStorage.cartItems ? JSON.parse(localStorage.cartItems) : []
  );
  const [quantity, setQuantity] = useState(
    localStorage.quantity !== undefined ? parseInt(localStorage.quantity) : 0
  );
  const [locale, setLocale] = useState(
    localStorage.locale ? localStorage.locale : "ru"
  );
  const [token, setToken] = useState(localStorage.token);
  const [fullName, setFullName] = useState(localStorage.full_name);
  const [headermenus, setHeadermenus] = useState(() => {
    ApiService.get("static/menu?place=header_menu").then((resp) => {
      return resp.data.results.data;
    });
  });
  return (
    <cartItemsContext.Provider value={[cartItems, setCartItems]}>
      <quantityContext.Provider value={[quantity, setQuantity]}>
        <localeContext.Provider value={[locale, setLocale]}>
          <tokenContext.Provider value={[token, setToken]}>
            <fullNameContext.Provider value={[fullName, setFullName]}>
              <headermenusContext.Provider
                value={[headermenus, setHeadermenus]}
              >
                {children}
              </headermenusContext.Provider>
            </fullNameContext.Provider>
          </tokenContext.Provider>
        </localeContext.Provider>
      </quantityContext.Provider>
    </cartItemsContext.Provider>
  );
};

export default Store;
