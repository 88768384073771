import React, { useEffect, useState } from "react";
import "../Authentication.scss";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { ReactComponent as User } from "../../../assets/user.svg";
import { Link } from "react-router-dom";
import ApiService from "../../../api/axios";
import InputMask from "react-input-mask";
import SimpleReactValidator from "simple-react-validator";
// import SocialLogin from "../SocialLogin";
import { _t } from "../../../helpers/helpers";

const Register = (props) => {
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [wrongEmail, setWrongEmail] = useState("");
  let validator = new SimpleReactValidator();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      ApiService.post("auth/register", {
        full_name: fullName,
        password,
        email,
        password_confirmation,
      }).then((response) => {
        console.log(response.data.errors);
        if (response.data.errors) {
          localStorage.setItem("token", response.data.results.token);
          localStorage.setItem("wishlist", response.data.results.user.wishlist);
          localStorage.setItem(
            "full_name",
            response.data.results.user.fullname
          );
          props.history.push("/");
          window.location.reload();
        } else {
          setWrongEmail(response.data.message);
        }
      });
    }
  };
  return (
    <div className="authentication register">
      <div className="auth-content">
        <div className="left">
          <Link to="/">
            <Logo />
          </Link>
          <p>{_t("content_login")}</p>
        </div>
        <div className="right">
          <div className="auth-content-child">
            <div className="authContentChildHeader">
              <Link to="/login" className="Login ">
                {_t("pages.login.title")}
              </Link>
              <Link to="/register" className="Register active">
                {_t("title.register")}
              </Link>
            </div>
            <form className="authContentBody" onSubmit={handleSubmit}>
              <div className="input-group input-group-validate">
                <input
                  type="text"
                  placeholder={_t("forms.name")}
                  name="full_name"
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>

              <div className="input-group input-group-validate">
                <input
                  type="email"
                  required
                  placeholder={_t("forms.emails")}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span>{wrongEmail}</span>
                {console.log(wrongEmail)}
              </div>

              <div className="input-group input-group-validate">
                <input
                  type="password"
                  placeholder={_t("forms.password")}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="input-group input-group-validate">
                <label htmlFor=""></label>
                <input
                  type="password"
                  placeholder={_t("forms.repeat_password")}
                  name="password_confirmation"
                  onChange={(e) => setPassword_confirmation(e.target.value)}
                />
              </div>
              <div className="input-group">
                <button className="blackButton">
                  <User />
                  {_t("title.register")}
                </button>
              </div>
              <div className="authContentFooter">
                <div className="authContentFooterChild">
                  <button>{_t("buttons_resend_code")}</button>
                </div>
              </div>
            </form>
            {/* <SocialLogin /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
