import React, { useContext, useEffect, useState } from "react";
import "./News.scss";
import NewsCard from "../../components/NewsCard";
import BreadCrumb from "../../components/BreadCrumb";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import { localeContext } from "../../components/Store";

const News = (props) => {
  const [newsList, setNewsList] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [locale, setLocale] = useContext(localeContext);

  useEffect(() => {
    ApiService.get("news/list").then((resp) => {
      setNewsList(resp.data.results.data);
      setPageMeta(resp.data.results.meta);
      setBreadcrumbs(resp.data.results.meta.breadcrumbs);
      console.log(resp.data.results.meta.meta_title);
    });
  }, [locale]);

  return (
    <div className="news">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="news-header">
          <h5 className="title">{pageMeta.meta_title}</h5>
        </div>
        <div className="row">
          {newsList.map((news) => (
            <div className="xl-4 lg-4 md-4 sm-12" key={news.id}>
              <NewsCard news={news} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
