import React, { useContext, useEffect, useState } from "react";
import "./HeaderTop.scss";
// img
import { ReactComponent as Facebook } from "../../../assets/facebook.svg";
import { ReactComponent as Instagram } from "../../../assets/instagram.svg";
import { ReactComponent as Youtube } from "../../../assets/youtube.svg";
import { ReactComponent as Tiktok } from "../../../assets/tiktok.svg";
import { ReactComponent as Telegram } from "../../../assets/telegram.svg";
import { ReactComponent as Vk } from "../../../assets/vk.svg";

import ApiService from "../../../api/axios";
import { localeContext } from "../../Store";
const HeaderTop = (props) => {
  // const [locale, setLocale] = useContext(localeContext)
  // const [languages, setLanguages] = useState([])
  // useEffect(() => {
  //     ApiService.get("helpers/languages").then(
  //         (resp) => {
  //             setLanguages(resp.data.results.data)
  //         }
  //     )
  // }, [locale])
  // const changeLocale = (e, language) => {
  //     ApiService.get(`helpers/locale/${language}`).then((resp) => {
  //         localStorage.setItem('locale', resp.data.data)
  //         // you don't need to set locale here, because after reload, the locale will be set from localStorage
  //         // setLocale(language)
  //         window.location.reload()
  //     })
  // }

  return (
    <div className="headerTop">
      <div className="container">
        <div className="left">
          {props.socials.facebook == "#" ? null : (
            <a target="_blank" href={props.socials.facebook}>
              <Facebook />
            </a>
          )}
          {props.socials.instagram == "#" ? null : (
            <a target="_blank" href={props.socials.instagram}>
              <Instagram />
            </a>
          )}
          {props.socials.youtube == "#" ? null : (
            <a target="_blank" href={props.socials.youtube}>
              <Youtube />
            </a>
          )}
          {props.socials.tiktok == "#" ? null : (
            <a target="_blank" href={props.socials.tiktok}>
              <Tiktok />
            </a>
          )}
          {props.socials.telegram == "#" ? null : (
            <a target="_blank" href={props.socials.telegram}>
              <Telegram />
            </a>
          )}
          {props.socials.vkontakte == "#" ? null : (
            <a target="_blank" href={props.socials.vkontakte}>
              <Vk />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
