import React, { Component } from 'react'
import { QuantityPicker } from 'react-qty-picker';

class Quantity extends Component {

    render() {

        return (
            <QuantityPicker min={1} value={1} />
        )
    }
}

export default Quantity