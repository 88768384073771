import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./AccountSidebar.scss";
import { _t } from "../../helpers/helpers";
import ApiService from "../../api/axios";

const AccountSidebar = () => {
  const [getOtherLogin, setGetOtherLogin] = useState(false);
  useEffect(() => {
    ApiService.get("/account").then((resp) => {
      if (resp.data.user.provider_id && resp.data.user.provider) {
        setGetOtherLogin(true);
      }
    });
  }, []);
  return (
    <div className="accountSidebar">
      <NavLink to="/account/personal-info" activeClassName="red">
        {_t("pages.personal_info.title")}
      </NavLink>
      <NavLink to="/account/favorites" activeClassName="red">
        {_t("pages.favorites.title")}
      </NavLink>
      <NavLink to="/account/order-history" activeClassName="red">
        {_t("pages.order_history.title")}
      </NavLink>
      {getOtherLogin ? null : (
        <NavLink to="/account/change-password" activeClassName="red">
          {_t("pages.change_password.title")}
        </NavLink>
      )}
      <NavLink to="/account/address" activeClassName="red">
        {_t("pages.addresses.title")}
      </NavLink>
    </div>
  );
};

export default AccountSidebar;
